import classNames from 'classnames';

export default function Container({children, className, edgePadding = true}) {
  return (
    <div className={classNames(
      'container xl:px-container',
      {'mx-4 max-md:w-auto md:mx-auto md:px-4': edgePadding},
      className
    )}
    >
      {children}
    </div>
  );
};
