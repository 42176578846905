import className from 'classnames/bind';
import styles from './PostAuthorFull.module.scss';
import PropTypes from "prop-types";
import {gql} from "@apollo/client";
import {getReactiveContent} from "@/lib/utils/helpers";
import Heading from "../Heading/Heading";
import PostAuthorImage from "../PostAuthorImage/PostAuthorImage";
import dynamic from "next/dynamic";

const cx = className.bind(styles);
const SocialLinks = dynamic(() => import('../SocialLinks/SocialLinks').then((mod) => mod.default));

export default function PostAuthorFull({className, publicAuthor}) {
  const {
    fullName,
    avatarUrlLarge,
    desc,
    title,
    email,
    metaTableHtml,
    socialFacebook,
    socialTwitter,
    socialInstagram,
    socialLinkedin,
    socialMastodon,
    socialWeb,
  } = publicAuthor;

  return (
    <div className={cx(['component', className])}>
      <PostAuthorImage className={cx('avatar')} size={200} alt={fullName} avatarUrl={avatarUrlLarge}/>

      <div className={cx('info')}>
        <p className={cx('title')}>{title}</p>
        <Heading className={cx('heading')} level={'h1'}>{fullName}</Heading>

        <div className={cx('desc')}>
          {getReactiveContent({htmlString: desc})}
        </div>

        <SocialLinks
          {
            ...{
              facebookUrl: socialFacebook,
              twitterUrl: socialTwitter,
              instagramUrl: socialInstagram,
              linkedinUrl: socialLinkedin,
              mastodonUrl: socialMastodon,
              webUrl: socialWeb,
              mailTo: email,
            }
          }
          align={'centerToLeft'}
          variant={'rounded'}
          className={cx('socialLinks')}
        />

        {getReactiveContent({htmlString: metaTableHtml})}
      </div>
    </div>
  );
}

PostAuthorFull.propTypes = {
  className: PropTypes.string,
  publicAuthor: PropTypes.object.isRequired,
};

PostAuthorFull.fragments = {
  key: 'PostAuthorFullFragment',
  entry: gql`
    fragment PostAuthorFullFragment on MomPublicUser {
      firstName
      fullName
      avatarUrlLarge
      uri
      desc
      title
      email
      metaTableHtml
      socialFacebook
      socialTwitter
      socialInstagram
      socialLinkedin
      socialMastodon
      socialWeb
      isReviewer
    }
  `
};
