import {gql} from "@apollo/client";
import Link from "next/link";
import PropTypes from "prop-types";

import Heading from "@/components/Heading/Heading";
import PostsGrid from "@/components/PostsGrid/PostsGrid";
import PostCard from "@/components/PostCard/PostCard";

export default function PostsFeatured({link, title, posts = []}) {
  const gridClassNames = {
    default: 'grid-cols-2 xl:grid-cols-4',
    lg: 'grid-cols-2',
  };

  const gridItemClassName = {
    default: '[&:nth-last-child(-n+2)]:mb-0 lg:[&:nth-last-child(-n+4)]:mb-0',
    lg: '[&:nth-last-child(-n+2)]:mb-0',
  };

  let variant = 'default';
  if (posts.length <= 2) {
    variant = 'lg';
  }

  return (
    <>
      <div className={`flex flex-nowrap justify-between items-center mb-4 gap-5`}>
        <Heading level={`h2`} className={`mom-section-heading`}>{title}</Heading>
        <Link className={`mom-btn mom-btn--sm shrink-0`} href={link}>See All</Link>
      </div>

      <PostsGrid
        posts={posts}
        gridClassName={gridClassNames[variant]}
        gridItemClassName={gridItemClassName[variant]}
        variant={`large`}
      />
    </>
  );
}

PostsFeatured.fragments = {
  key: 'MomPostsFeatured',
  entry: gql`
    ${PostCard.fragments.entry}
    fragment MomPostsFeatured on MomFeatureCategory {
      link
      title
      posts {
        ...${PostCard.fragments.key}
      }
    }
  `
};

PostsFeatured.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object),
};
