import {Suspense} from "react";
import classNames from 'classnames';
import Link from "next/link";
import {AnimatePresence} from "framer-motion";
import dynamic from "next/dynamic";

import {useStickyNewsletterStore} from "@/lib/stores/layout";
import ImgLogo from "@/lib/assets/images/logo-footer-w.svg";
import {getReactiveContent, getStyledTitle} from "@/lib/utils/helpers";
import Container from '@/components/Container/Container';
import Heading from '@/components/Heading/Heading';
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu';
import Loading from "@/components/Loading/Loading";
import SocialProfiles from "@/components/SocialProfiles/SocialProfiles";

const NewsletterPopup = dynamic(() => import('@/components/Newsletter/NewsletterPopup').then((mod) => mod.default));
const Newsletter = dynamic(() => import('@/components/Newsletter/Newsletter').then((mod) => mod.default));

export default function Footer(
  {
    subMenuA,
    subMenuB,
    siteSettings,
    style = {},
    className = 'mt-8 lg:mt-10'
  }
) {
  const year = new Date().getFullYear();
  const isPopupVisible = useStickyNewsletterStore(state => state.visible);

  return (
    <Suspense fallback={<Loading/>}>
      <footer
        className={classNames(
          className,
          'relative after:content-[\'\'] after:absolute after:top-[calc(100%-1px)] after:left-0 after:w-full',
          'after:h-[50px] md:after:h-[90px]'
        )}
        style={style}
      >
        <Container className={`mom-section`}>
          <SocialProfiles {...siteSettings}/>
        </Container>

        <Container className={`mom-section`}>
          <div className={classNames(
            `flex flex-col flex-wrap items-center md:items-start justify-between text-center`,
            `md:justify-between md:text-left md:flex-row md:order-none`,
            '[&_li]:block [&_li]:py-2'
          )}>
            <NavigationMenu
              menuItems={subMenuA}
              className={`flex items-center md:w-1/3 order-2 md:order-none`}
            />

            <div className={classNames(
              'text-center',
              'md:w-1/3 order-1 md:order-none',
              'max-md:px-6 max-md:mb-16'
            )}>
              <Heading level={'h3'} className={`lg:text-[34px]`}>
                {getStyledTitle({title: 'Want to join  our exclusive community?', italicLast: false})}
              </Heading>
              <Newsletter showAwards={true} heading={''} description={''}/>

              <Link
                href={'/'}
                className={classNames(
                  'mt-14',
                  'inline-block',
                  'w-32 max-w-32'
                )}
                aria-label={'Man of Many'}
                prefetch={false}
              >
                <ImgLogo className={`w-full`}/>
              </Link>
            </div>

            <NavigationMenu
              menuItems={subMenuB}
              className={`flex items-center md:w-1/3 order-3 md:order-none md:justify-end md:text-right`}
            />
          </div>
        </Container>

        <Container className={`mom-section text-center text-sm`}>
          <div className="mx-auto max-w-[875px]">
            {siteSettings?.footerAffiliateDisclaimer && (
              <>
                {getReactiveContent({htmlString: siteSettings.footerAffiliateDisclaimer, applyAutoP: true})}
              </>
            )}

            <p className={`text-gray-500 dark:text-gray`}>
              © {year} Man of Many Pty Ltd – Sydney, Australia ABN 73 163 331 280<br/>
              MAN OF MANY® and its Logos are registered trademarks of Man of Many Pty Ltd.
            </p>
          </div>
        </Container>
      </footer>

      <AnimatePresence>
        {isPopupVisible && (
          <NewsletterPopup/>
        )}
      </AnimatePresence>
    </Suspense>
  );
}
