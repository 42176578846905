import Link from "next/link";
import {gql} from "@apollo/client";
import PropTypes from "prop-types";
import classNames from 'classnames';

import {getPrimaryCategory} from "@/lib/utils/helpers";
import {CARD_SIZE, EXCLUDED_TERMS} from "@/lib/utils/constants";

export default function TermLinks(
  {
    terms,
    className,
    primaryOnly = false,
    variant = 'default'
  }
) {
  let edges = terms?.edges;

  if (!edges?.length) {
    return null;
  }

  if (primaryOnly) {
    edges = [getPrimaryCategory(terms?.edges)];
  }

  const isSmallVariant = variant === 'small';

  return (
    <div className={className}>
      {edges.map((term, key) => (
        !EXCLUDED_TERMS.includes(term.node.slug) &&
        <Link
          className={classNames(
            'mr-2',
            {'mb-2': !primaryOnly},
            {'mom-pill': !isSmallVariant},
            {'mom-term': isSmallVariant},
          )}
          key={key}
          href={term.node.uri}
        >
          {term.node.name.toUpperCase()}
        </Link>
      ))}
    </div>
  );
}

TermLinks.fragments = {
  keyCategory: 'PostToCategoryFragment',
  entryCategory: gql`
    fragment PostToCategoryFragment on PostToCategoryConnection {
      edges {
        isPrimary
        node {
          uri
          name
          slug
        }
      }
    }
  `,
  keyTag: 'PostToTagFragment',
  entryTag: gql`
    fragment PostToTagFragment on PostToTagConnection {
      edges {
        isPrimary
        node {
          uri
          name
          slug
        }
      }
    }
  `,
  keyRootTag: 'RootQueryToTagConnectionFragment',
  entryRootTag: gql`
    fragment RootQueryToTagConnectionFragment on RootQueryToTagConnection {
      edges {
        node {
          uri
          name
          slug
        }
      }
    }
  `,
};

TermLinks.propTypes = {
  primaryOnly: PropTypes.bool,
  terms: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(CARD_SIZE),
};
