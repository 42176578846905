export default function FormatDate({date}) {
  let formattedDate = new Date(date);

  if (isNaN(formattedDate.valueOf())) {
    return null;
  }

  const timeFormat = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };

  return <>{formattedDate.toLocaleDateString('en-AU', timeFormat)}</>;
}
