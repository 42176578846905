import className from 'classnames/bind';
import classNames from 'classnames';
import styles from './ContentWrapper.module.scss';
import {getReactiveContent} from "@/lib/utils/helpers";
import PropTypes from "prop-types";
import Loading from "@/components/Loading/Loading";
import {Suspense} from "react";

const cx = className.bind(styles);

export default function ContentWrapper(
  {
    content,
    className,
    headerContent = null,
    railContent = null,
    beforeContent = null,
    showAd = false,
    postPositionIndex = 0,
    isFullWidth = false
  }
) {

  return (
    <article className={cx(
      'mx-auto max-w-[var(--mom--content--max-width)]',
      {'max-w-full': isFullWidth},
      className,
    )}>
      <Suspense fallback={<Loading/>}>
        {headerContent}

        <div className="flex flex-nowrap">
          <div className={cx(
            'component',
            `w-full`
          )}>
            {beforeContent}

            {getReactiveContent({
              htmlString: content,
              applyAutoP: false,
              applyImgWrapper: true,
              applyAd: showAd,
              applyAdPostPositionIndex: postPositionIndex,
            })}
          </div>

          {!!railContent && (
            <aside className={classNames(`w-[100px] shrink-0 hidden md:block`)}>
              {railContent}
            </aside>
          )}
        </div>
      </Suspense>
    </article>
  );
};

ContentWrapper.propTypes = {
  content: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
  showAd: PropTypes.bool,
  railContent: PropTypes.object,
  beforeContent: PropTypes.object,
  postPositionIndex: PropTypes.number
};
