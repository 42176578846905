import Head from 'next/head';
import {gql} from "@apollo/client";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import {getAdjacentUri, getConcatenatedUrlParts} from "@/lib/utils/helpers";
import {useShareDateStore} from "@/lib/stores/post";
import appleIcon180 from "@/lib/assets/images/favicon/apple-icon-180x180.png";
import icon192 from "@/lib/assets/images/favicon/android-icon-192x192.png";
import icon96 from "@/lib/assets/images/favicon/favicon-96x96.png";
import icon32 from "@/lib/assets/images/favicon/favicon-32x32.png";
import icon16 from "@/lib/assets/images/favicon/favicon-16x16.png";
import React from "react";

const sharedFields = `
  breadcrumbs {
    url
    text
  }
  title
  canonical
  fullHead
  metaRobotsNoindex
`;

const processWPUrl = ({wpUrl, canonical}) => {
  // Prioritize canonical
  wpUrl = canonical?.trim().length ? canonical.trim() : wpUrl;

  if (wpUrl && !wpUrl.match(/^(http|https):\/\//i)) {
    // In case canonical is a relative URL, prepend NEXT_PUBLIC_WORDPRESS_URL, so we can do full replacement later
    wpUrl = getConcatenatedUrlParts(process.env.NEXT_PUBLIC_WORDPRESS_URL, `/${wpUrl}`);
  }

  return wpUrl;
};

const processFullHead = ({fullHead, wpUrl, paged}) => {
  if (fullHead && wpUrl) {
    // Append the current page number to any wpUrl in fullHead that are not images, starting with NEXT_PUBLIC_WORDPRESS_URL
    fullHead = fullHead.replaceAll(
      new RegExp(`(${wpUrl}[^"'#]*)`, 'gi'),
      (match, p1) => {
        if (p1.match(/\.(png|jpg|jpeg|gif|svg)$/i)) {
          return p1;
        }

        return getConcatenatedUrlParts(p1, `${paged > 1 ? `/page/${paged}` : ''}`);
      }
    );

    // Replace all WP URL left with NEXT_PUBLIC_FRONTEND_URL, except for images
    fullHead = fullHead.replaceAll(process.env.NEXT_PUBLIC_WORDPRESS_URL, process.env.NEXT_PUBLIC_FRONTEND_URL);

    // Fix relative URL for breadcrumb
    fullHead = fullHead.replaceAll(new RegExp('"item":"(\\/[^"\\s]+)"', "g"), `"item":"${process.env.NEXT_PUBLIC_FRONTEND_URL}$1"`);
    fullHead = fullHead.replaceAll(new RegExp('"item":"\\/"', "g"), `"item":"${process.env.NEXT_PUBLIC_FRONTEND_URL}"`);
  }

  return fullHead;
};

export default function SEO(
  {
    title,
    description,
    imageUrl,
    url,
    seo,
    paged = 1,
    noindex = false,
    useShareDataTitle = false,
    hasMore,
    hasPrevious
  }
) {
  const {
    metaRobotsNoindex,
    fullHead: seoFullHead,
    canonical: seoCanonical,
    title: seoTitle
  } = seo || {};

  if (
    !seoFullHead &&
    !seoCanonical &&
    !seoTitle &&
    !title &&
    !description &&
    !imageUrl &&
    !url
  ) {
    return null;
  }

  // Refine the URL (Yoast SEO canonical doesn't have page number at this stage)
  const processedWPUrl = processWPUrl({wpUrl: url, canonical: seoCanonical});

  // Clean up full head
  const processedFullHead = processFullHead({fullHead: seoFullHead, wpUrl: processedWPUrl, paged});

  // Clean up canonical
  const processedCanonicalSrc = processedWPUrl?.replaceAll(process.env.NEXT_PUBLIC_WORDPRESS_URL, process.env.NEXT_PUBLIC_FRONTEND_URL);

  // Used for updating <title>, as using document.title is not recommended
  const shareData = useShareDateStore();

  return (
    <Head>
      {/* Title */}
      {((pageTitle) => {
        // Add paged to pageTitle, but before the "|" char
        const pageTitleParts = pageTitle.split('|');
        const modifiedTitle = `${pageTitleParts[0]}${paged > 1 ? ` - Page ${paged}` : ''}${pageTitleParts.length > 1 ? ' |' + pageTitleParts.slice(1).join('|') : ''}`;

        return (
          <>
            <title>{(useShareDataTitle && shareData?.title) ? shareData.title : modifiedTitle}</title>
            <meta name="title" content={modifiedTitle}/>
          </>
        );
      })(seoTitle || title)}

      {/* Full head */}
      {processedFullHead ? parse(processedFullHead) : (
        <>
          {/* NextJS Head cannot have nested elements */}
          {/* Must be direct child, or max 1 nested level, which has been used for outer <></> */}
          {/* So, must conditionally add tags one by one like below */}

          {description && <meta name="description" content={description}/>}
          {description && <meta property="og:description" content={description}/>}
          {description && <meta property="twitter:description" content={description}/>}

          {imageUrl && <meta property="og:image" content={imageUrl}/>}
          {imageUrl && <meta property="twitter:image" content={imageUrl}/>}

          {processedCanonicalSrc && <link rel="canonical" href={getAdjacentUri(processedCanonicalSrc, paged)}/>}
          {processedCanonicalSrc && <meta property="og:url" content={getAdjacentUri(processedCanonicalSrc, paged)}/>}
          {processedCanonicalSrc && (
            <meta property="twitter:url" content={getAdjacentUri(processedCanonicalSrc, paged)}/>
          )}
        </>
      )}

      {/* Pagination */}
      {processedCanonicalSrc && (
        <>
          {hasMore && (
            <link rel="next" href={`${getAdjacentUri(processedCanonicalSrc, paged, 1)}`}/>
          )}
          {hasPrevious && (
            <link rel="prev" href={`${getAdjacentUri(processedCanonicalSrc, paged, -1)}`}/>
          )}
        </>
      )}

      {/* Robots */}
      {(metaRobotsNoindex === 'noindex' || noindex) && <meta name="robots" content="noindex, follow"/>}

      {/* Manifest */}
      <link rel="apple-touch-icon" sizes="180x180" href={appleIcon180.src}/>
      <link rel="icon" type="image/png" sizes="192x192" href={icon192.src}/>
      <link rel="icon" type="image/png" sizes="96x96" href={icon96.src}/>
      <link rel="icon" type="image/png" sizes="32x32" href={icon32.src}/>
      <link rel="icon" type="image/png" sizes="16x16" href={icon16.src}/>
      <meta name="theme-color" content="#000000"/>

      {/* Preloads */}
      {/*<link rel="preload" href="https://ads.pubmatic.com/AdServer/js/pwt/163463/10608/pwt.js" as="script"/>
      <link rel="preload" href="https://securepubads.g.doubleclick.net/tag/js/gpt.js" as="script"/>*/}

      {/* Pre-connects */}
      <link rel="preconnect" href="https://ads.pubmatic.com"/>
      <link rel="preconnect" href="https://securepubads.g.doubleclick.net"/>
      <link rel="preconnect" href="https://ajax.cloudflare.com"/>
      <link rel="preconnect" href="https://clientcdn.pushengage.com"/>
      <link rel="preconnect" href="https://s0.2mdn.net"/>
      <link rel="preconnect" href="https://ssl.p.jwpcdn.com"/>
      <link rel="preconnect" href="https://cdn.jwplayer.com"/>
      <link rel="preconnect" href="https://connect.facebook.net"/>
      <link rel="preconnect" href="https://www.googletagmanager.com"/>
      <link rel="preconnect" href="https://b-code.liadm.com"/>
    </Head>
  );
}

SEO.fragments = {
  keyTaxonomy: 'TaxonomySEOFragment',
  entryTaxonomy: gql`
    fragment TaxonomySEOFragment on TaxonomySEO {
      ${sharedFields}
    }
  `,

  keyPostType: 'PostTypeSEOFragment',
  entryPostType: gql`
    fragment PostTypeSEOFragment on PostTypeSEO {
      ${sharedFields}
    }
  `,

  keyUser: 'UserSEOFragment',
  entryUser: gql`
    fragment UserSEOFragment on SEOUser {
      title
      canonical
      fullHead
    }
  `,
};

SEO.propTypes = {
  seo: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  url: PropTypes.string,
  paged: PropTypes.number,
  hasMore: PropTypes.bool,
  hasPrevious: PropTypes.bool,
};
