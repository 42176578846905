import {useEffect, useRef, useState, useTransition} from "react";
import {useInView} from "framer-motion";
import classNames from 'classnames/bind';
import styles from './ViewpointTrigger.module.scss';
import PropTypes from "prop-types";

const cx = classNames.bind(styles);

export default function ViewpointTrigger({children, className, callback, viewPercentage = 50}) {
  const ref = useRef(null);
  const [, startTransition] = useTransition();
  const [enableAnchor, setEnableAnchor] = useState(false);
  const isAnchorInView = useInView(ref, {
    margin: `9999px 0px 0px 0px`,
    once: true,
  });

  // Delay anchor creation to prevent callback from firing immediately
  useEffect(() => {
    setTimeout(() => {
      startTransition(() => {
        setEnableAnchor(true);
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (isAnchorInView && enableAnchor) {
      callback();
    }
  }, [isAnchorInView, enableAnchor]);

  return (
    <div className={cx(['component', className])}>
      {children}

      {/* Anchor positioned at viewPercentage position */}
      <aside
        ref={ref} className={cx('anchor')}
        style={{
          top: `${viewPercentage}%`,
          display: (enableAnchor ? 'block' : 'none')
        }}
      />
    </div>
  );
}

ViewpointTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  viewPercentage: PropTypes.number,
  callback: PropTypes.func.isRequired,
};
