import classNames from 'classnames';
import React from "react";
import PostShare from "@/components/PostShare/PostShare";

export default function PostActions({className}) {
  return (
    <div
      className={classNames(
        'd-flex max-md:py-4 max-md:mom-border max-md:border-y-2',
        className
      )}>
      <span className={`relative`}><PostShare variant={'fill'}/></span>
    </div>
  );
}

PostActions.propTypes = {};
