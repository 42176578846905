import {MAIN_CONTENT_ID} from '@/lib/utils/constants';

export default function Main({children, className, ...props}) {
  return (
    <main
      id={MAIN_CONTENT_ID}
      tabIndex={-1}
      className="outline-0"
      {...props}
    >
      {children}
    </main>
  );
}
