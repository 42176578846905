import classNames from 'classnames';
import dynamic from "next/dynamic";
import {Suspense} from "react";

import {usePrimaryMenuStore, useSearchOverlayStore} from "@/lib/stores/layout";
import HeaderTop from '@/components/Header/HeaderTop';
import HeaderSubNav from '@/components/Header/HeaderSubNav';
import Loading from "@/components/Loading/Loading";
import HeaderBottom from "@/components/Header/HeaderBottom";

const HeaderNav = dynamic(() => import('./HeaderNav').then((mod) => mod.default));
const HeaderSearch = dynamic(() => import('./HeaderSearch').then((mod) => mod.default), {ssr: false});
const AnimatePresence = dynamic(() => import('framer-motion').then(mod => mod.AnimatePresence));

export default function Header(
  {
    primaryMenu,
    subNavHtml,
    siteSettings,
    subMenuA,
    subMenuB,
    popularTags,
    enableShare = false
  }
) {
  const isSearchVisible = useSearchOverlayStore(state => state.visible);
  const isPrimaryMenuVisible = usePrimaryMenuStore(state => state.visible);

  return (
    <Suspense fallback={<Loading/>}>
      <header className={classNames(
        'text-center relative z-20 min-h-header',
      )}>
        <div className={classNames(
          'fixed left-0 top-0 w-full bg-white dark:bg-black z-[14]',
          'min-h-header md:min-h-0',
          'admin-bar:top-8'
        )}>
          <HeaderTop enableShare={enableShare}/>

          <HeaderBottom {...{primaryMenu}}/>

          {subNavHtml && <HeaderSubNav html={subNavHtml}/>}
        </div>

        <AnimatePresence>
          {/* Can use isPrimaryMenuVisible here, as HeaderBottom has already covered links for SEO purposes */}
          {isPrimaryMenuVisible && <HeaderNav {...{primaryMenu, subMenuA, subMenuB, siteSettings, popularTags}}/>}
        </AnimatePresence>

        <AnimatePresence>
          {isSearchVisible && <HeaderSearch/>}
        </AnimatePresence>
      </header>
    </Suspense>
  );
}
