import {useCallback, useEffect, useRef, useState} from 'react';
import {usePrimaryMenuStore, useSearchOverlayStore, useStickyNewsletterStore} from "../stores/layout";
import {useRouter} from "next/router";
import {useThrottle, useWindowSize} from "@uidotdev/usehooks";

/**
 * This hook should be used in mountable/unmountable components, to avoid memory leaks.
 */
export function useRouterChange() {
  const router = useRouter();
  const hideNewsletter = useStickyNewsletterStore(state => state.hide);
  const hidePrimaryMenu = usePrimaryMenuStore(state => state.hide);
  const hideSearch = useSearchOverlayStore(state => state.hide);

  useEffect(() => {
    const handleRouteChange = () => {
      hideNewsletter();
      hidePrimaryMenu();
      hideSearch();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
}

/**
 * This hook has been used in HeaderTop component already. DO NOT USE IT AGAIN.
 * @param callback
 */
export function useEscKeyPress(callback) {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        callback();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
}

/**
 * Throttle window width change detection
 * @returns {number|number}
 */
export function useThrottledWindowWidth() {
  const size = ('undefined' !== typeof window) ? useWindowSize() : {width: 0};
  return useThrottle(size.width, 500);
}

/**
 * Throttle view port change detection
 * @param callback
 */
export function useViewPortChange(callback) {
  const size = 'undefined' !== typeof window ? window.innerWidth : 0;
  const [initialWidth, setInitialWidth] = useState(size);
  const throttledResized = useThrottledWindowWidth();

  // Refresh ads on browser resize
  useEffect(() => {
    const afterResize = window.innerWidth;
    if (
      (initialWidth < 1200 && afterResize >= 1200) || (initialWidth >= 1200 && afterResize < 1200) ||
      (initialWidth < 992 && afterResize >= 992) || (initialWidth >= 992 && afterResize < 992) ||
      (initialWidth < 768 && afterResize >= 768) || (initialWidth >= 768 && afterResize < 768) ||
      (initialWidth < 640 && afterResize >= 640) || (initialWidth >= 640 && afterResize < 640)
    ) {
      setInitialWidth(afterResize);
      callback();
    }
  }, [throttledResized]);
}

/**
 * Hook to make an element draggable horizontally
 * @param ref
 * @returns {{onClickCapture: ((function(*): void)|*), onMouseUp: ((function(*): void)|*), onMouseLeave: ((function(*): void)|*), onMouseMove: ((function(*): void)|*), onMouseDown: ((function(*): void)|*)}}
 */
export function useDraggableScroll(ref) {
  const dragOffset = 5;

  let dragTimeout = null;
  let isDragging = false;
  let hasDragged = false;
  let startX = 0;
  let scrollLeft = 0;
  let clickStartX = 0;

  const onMouseDown = useCallback((e) => {
    e.preventDefault();
    isDragging = true;
    startX = e.pageX - ref.current.offsetLeft;
    scrollLeft = ref.current.scrollLeft;
    clickStartX = e.pageX;
  }, [ref]);

  const onMouseUp = useCallback((e) => {
    isDragging = false;
    const dragDistance = Math.abs(e.pageX - clickStartX);
    if (dragDistance > dragOffset) {
      if (dragTimeout) clearTimeout(dragTimeout);
      dragTimeout = setTimeout(() => {
        hasDragged = false;
      }, 50);
    }
  }, []);

  const onMouseMove = useCallback((e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX);
    ref.current.scrollLeft = scrollLeft - walk;

    if (Math.abs(walk) > dragOffset) {
      hasDragged = true;
    }
  }, [ref]);

  const onClickCapture = useCallback((e) => {
    if (hasDragged) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  return {
    onMouseDown,
    onMouseUp,
    onMouseLeave: onMouseUp,
    onMouseMove,
    onClickCapture,
  };
}

export function useHoverIntent() {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const onMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsOpen(true);
    }, 200);
  };

  const onMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(false);
  };

  return {
    isOpen,
    onMouseEnter,
    onMouseLeave,
  };
}
