import className from 'classnames/bind';
import styles from './Carousel.module.scss';
import {useState, useTransition} from "react";
import Button from "@/components/Button/Button";
import {gql} from "@apollo/client";
import Image from "next/image";

const cx = className.bind(styles);

export default function Carousel({className, gallery}) {
  const [, startTransition] = useTransition();
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = gallery?.edges?.map(({node}) => node) || [];

  const goToPrevious = () => {
    startTransition(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    });
  };

  const goToNext = () => {
    startTransition(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    });
  };

  const getOffset = () => {
    return -currentIndex * 100;
  };

  return (
    <div className={cx(['component', className])}>
      <div className={cx('inner')} style={{transform: `translateX(${getOffset()}%)`}}>
        {images.map((image, index) => (
          <div key={index} className={cx('image')}>
            <Image
              fill
              src={image.sourceUrl}
              alt={image?.altText}
              sizes={'(max-width: 768px) 50vw, (max-width: 1200px) 50vw, 65vw'}
            />
          </div>
        ))}
      </div>

      <Button className={cx('prev')} onClick={goToPrevious}>Previous</Button>
      <Button className={cx('next')} onClick={goToNext}>Next</Button>

      <div className={cx('pagination')}>{currentIndex + 1}/{images.length}</div>
    </div>
  );
};

Carousel.fragments = {
  key: `CarouselFragment`,
  entry: gql`
    fragment CarouselFragment on AcfMediaItemConnection {
      edges {
        node {
          id
          sourceUrl
          altText
          mediaDetails {
            width
            height
          }
        }
      }
    }
  `
};
