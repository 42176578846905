import React from 'react';
import classNames from "classnames";
import Moon from '@/lib/assets/images/moon.svg';
import Sun from '@/lib/assets/images/sun.svg';
import {useTheme} from 'next-themes';

const ThemeToggle = ({className}) => {
  const {theme, setTheme} = useTheme();
  const isDarkMode = theme === 'dark';

  const toggleDarkMode = () => {
    setTheme(isDarkMode ? 'light' : 'dark');
  };

  return (
    <div className={className}>
      <button
        onClick={toggleDarkMode}
        className={classNames(
          'relative flex items-center h-7 w-12 rounded-full transition-colors',
          'focus:outline-none',
          'bg-black dark:bg-white',
        )}
      >
        <span className="sr-only">Toggle theme</span>
        <span className={classNames(
          `absolute inset-0 flex justify-between items-center px-2`,
          'text-white dark:text-black',
        )}>
          <Moon className=""/>
          <Sun className=""/>
        </span>
        <span
          className={classNames(
            'inline-block w-5 h-5 transform transition-transform rounded-full',
            'bg-white dark:bg-black',
            'translate-x-1 dark:translate-x-6'
          )}
        />
      </button>
    </div>
  );
};

export default ThemeToggle;
