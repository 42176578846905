import {gql} from "@apollo/client";
import Link from 'next/link';
import classNames from 'classnames';
import PropTypes from "prop-types";

import Heading from "../Heading/Heading";
import FeaturedImage from "../FeaturedImage/FeaturedImage";
import TermLinks from "../TermLinks/TermLinks";
import {CARD_SIZE} from "@/lib/utils/constants";
import {getStyledTitle} from "@/lib/utils/helpers";

const imageDimensions = {
  default: {width: 308, height: 185},
  small: {width: 104, height: 104},
  large: {width: 635, height: 380},
};

export default function PostCard(
  {
    title,
    uri,
    categories,
    featuredImage,
    featuredImagePriority = false,
    className,
    momHasVideo,
    isFeatured = false, // True to replace term link with featured text
    variant = 'default',
  }
) {
  const imageSize = imageDimensions[variant] || imageDimensions.default;
  const isSmallVariant = variant === 'small';

  return (
    <div className={classNames(`group/PostCard`, className)}>
      <Link
        href={uri}
        className={classNames(
          'no-underline block',
          {'mom-has-video': momHasVideo},
          {'float-left mr-4 size-[104px]': isSmallVariant},
          {'mb-4': !isSmallVariant},
        )}
        aria-label={title}
      >
        <FeaturedImage
          {...imageSize}
          priority={featuredImagePriority}
          image={featuredImage?.node}
          style={{aspectRatio: `${imageSize.width}/${imageSize.height}`}}
        />
      </Link>

      <div className={classNames({
        'mb-2 md:mb-4': !isSmallVariant,
        'mb-1': isSmallVariant
      })}
      >
        {!isFeatured
          ? <TermLinks terms={categories} primaryOnly={true} variant={variant}/>
          : <span className={`mom-pill`}>FEATURED POST</span>
        }
      </div>

      <Heading level={'h3'} className={classNames(
        'text-sm',
        {'font-sans line-clamp-4': isSmallVariant},
        {'md:text-h4 mb-0': !isSmallVariant},
      )}>
        <Link href={uri} title={title} className={`no-underline`}>{getStyledTitle({title})}</Link>
      </Heading>
    </div>
  );
};

PostCard.fragments = {
  key: 'PostCardFragment',
  entry: gql`
    ${FeaturedImage.fragments.entry}
    ${TermLinks.fragments.entryCategory}
    fragment PostCardFragment on Post {
      featuredImage {
        ...${FeaturedImage.fragments.key}
      }
      databaseId
      title
      uri
      momHasVideo
      categories {
        ...${TermLinks.fragments.keyCategory}
      }
    }
  `
};

PostCard.propTypes = {
  categories: PropTypes.object.isRequired,
  className: PropTypes.string,
  featuredImage: PropTypes.object,
  featuredImagePriority: PropTypes.bool,
  isFeatured: PropTypes.bool,
  momHasVideo: PropTypes.bool,
  variant: PropTypes.oneOf(CARD_SIZE),
  title: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
};
