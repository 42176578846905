import {gql} from '@apollo/client';
// import {COOKIE_NEWSLETTER_POPUP_SHOWN} from '@/lib/utils/constants';
import * as constants from '@/lib/utils/constants';
import {NOINDEX_POSTS_IN_CATEGORY, NOINDEX_POSTS_IN_TAGS} from '@/lib/utils/constants';
import {FooterFragment, GlobalFragment} from '@/lib/fragments';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import Main from '@/components/Main/Main';
import NavigationMenu from '@/components/NavigationMenu/NavigationMenu';
import Post, {PostMemo} from '@/components/Post/Post';
import PostCard from '@/components/PostCard/PostCard';
import SEO from '@/components/SEO/SEO';
// import {useStickyNewsletterStore} from "@/lib/stores/layout";
// import Cookies from "js-cookie";
import {useCallback, useState} from "react";
import ViewpointTrigger from "@/components/ViewpointTrigger/ViewpointTrigger";
import dynamic from "next/dynamic";
import TermLinks from "@/components/TermLinks/TermLinks";

const AjaxLoadMore = dynamic(() => import('@/components/AjaxLoadMore/AjaxLoadMore').then((mod) => mod.default), {ssr: false});
const AdSlotOneByOne = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OneByOne));
const AdSlotOutOfSpace = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.OutOfSpace));

export default function Component(props) {
  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  // Main post
  const {data} = props;
  const {
    post,
    momTrendingPosts: latestPosts,
    momSubNavHeader: subNavHtml,
    momPromotedSidebarPosts: promotedPosts,
    headlessSiteSettings: {siteSettings} = {},
    primaryMenuItems: {nodes: primaryMenu} = {},
    subMenuAItems: {nodes: subMenuA} = {},
    subMenuBItems: {nodes: subMenuB} = {},
    popularTags = [],
  } = data || {};

  const {postSettings: {blockContinuousScroll}, momShowAd} = post || {};
  // const showNewsletter = useStickyNewsletterStore(state => state.show);
  const [enableLoadMore, setEnableLoadMore] = useState(false);

  // Trigger set of actions when viewport reach 50% of first post
  const viewpointCallback = useCallback(() => {
    // Show newsletter popup
    /*if (!Cookies.get(COOKIE_NEWSLETTER_POPUP_SHOWN)) {
      showNewsletter();
    }*/

    // Enable load more
    if (!blockContinuousScroll) {
      setEnableLoadMore(true);
    }
  }, []);

  // All posts under certain tags are noindex by default
  const {tags} = post || {};
  const forceNoindexByTag = tags?.edges?.some(({node}) => NOINDEX_POSTS_IN_TAGS.includes(node?.slug));

  // All posts published before 5 May 2024 and have Sponsored category are noindex by default
  const {date, categories} = post || {};
  const forceNoindexByCategory = new Date(date) < new Date('2024-05-05T00:00:00') && categories?.edges?.some(({node}) => NOINDEX_POSTS_IN_CATEGORY.includes(node?.slug));

  return (
    <>
      <SEO
        seo={{...post?.seo, ...((forceNoindexByTag || forceNoindexByCategory) ? {metaRobotsNoindex: 'noindex'} : {})}}
        title={post?.title}
        description={post?.title}
        useShareDataTitle={true}
      />

      <Header {...{primaryMenu, subNavHtml, subMenuA, subMenuB, siteSettings, popularTags}} enableShare={true}/>

      <Main>
        <ViewpointTrigger callback={viewpointCallback}>
          <PostMemo
            siteSettings={siteSettings}
            post={post}
            latestNews={latestPosts}
            promotedPosts={promotedPosts}
          />
        </ViewpointTrigger>

        {enableLoadMore && (
          <AjaxLoadMore
            initialPost={post}
            sharedLatestNews={latestPosts}
            sharedPromotedPosts={promotedPosts}
          />
        )}
      </Main>

      <Footer {...{subMenuA, subMenuB, siteSettings}} style={{marginTop: 0}}/>

      {momShowAd && (
        <>
          <AdSlotOneByOne objectType={'article'}/>
          <AdSlotOutOfSpace objectType={'article'}/>
        </>
      )}
    </>
  );
}

Component.query = gql`
  ${FooterFragment}
  ${GlobalFragment}
  ${Post.fragments.entryPost}
  ${PostCard.fragments.entry}
  ${NavigationMenu.fragments.entry}
  ${TermLinks.fragments.entryRootTag}
  query GetPost(
    $excludes: [Int]
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $subMenuALocation: MenuLocationEnum
    $subMenuBLocation: MenuLocationEnum
    $asPreview: Boolean = false
  ) {
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      ...${Post.fragments.keyPost}
      momShowAd
      postSettings {
        blockContinuousScroll
      }
      momNextPost(excludes: $excludes) {
        databaseId
      }
    }
    momSubNavHeader(objectDatabaseID: $databaseId)
    momAdTargeting(objectDatabaseID: $databaseId)
    momPromotedSidebarPosts {
      ...${PostCard.fragments.key}
    }
    momTrendingPosts {
      ...${PostCard.fragments.key}
    }
    headlessSiteSettings {
      ...GlobalFragment
      ...FooterFragment
    }
    primaryMenuItems: menuItems(where: { location: $headerLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuAItems: menuItems(where: { location: $subMenuALocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    subMenuBItems: menuItems(where: { location: $subMenuBLocation }, first: 100) {
      nodes {
        ...${NavigationMenu.fragments.key}
      }
    }
    popularTags: tags(first: 20, where: {orderby: COUNT, order: DESC}) {
      ...${TermLinks.fragments.keyRootTag}
    }
  }
`;

// Just leave here for now, as latest posts have been changed to trending posts node
// ${Post.fragments.entryLatestPosts}
// posts(first: 5, where: {notIn: [$databaseId]}) {
// ...${Post.fragments.keyLatestPosts}
// }

Component.variables = ({databaseId}, ctx) => {
  return {
    databaseId,
    excludes: [databaseId],
    headerLocation: constants.MENU_PRIMARY_LOCATION,
    subMenuALocation: constants.MENU_FOOTER_LEFT_LOCATION,
    subMenuBLocation: constants.MENU_FOOTER_RIGHT_LOCATION,
    asPreview: ctx?.asPreview,
  };
};
