import styles from './Post.module.scss';
import {gql} from "@apollo/client";
import {useInView} from "framer-motion";
import {useShareDateStore} from "@/lib/stores/post";
import className from "classnames/bind";
import PropTypes from "prop-types";
import {memo, Suspense, useContext, useEffect, useRef} from "react";
import dynamic from 'next/dynamic';

import {getPrimaryCategory, sendIpsosEvent} from "@/lib/utils/helpers";
import PostHeader from "@/components/PostHeader/PostHeader";
import Heading from "@/components/Heading/Heading";
import PostCard from "@/components/PostCard/PostCard";
import PostFooter from "@/components/PostFooter/PostFooter";
import Container from "@/components/Container/Container";
import ContentWrapper from "@/components/ContentWrapper/ContentWrapper";
import {SlotLevelAdTargetContext} from "@/lib/utils/context";
import InjectBanner from "@/components/InjectBanner/InjectBanner";
import Loading from "@/components/Loading/Loading";
import Newsletter from "@/components/Newsletter/Newsletter";
import classNames from "classnames";
import PostInfo from "@/components/PostInfo/PostInfo";
import TermLinks from "@/components/TermLinks/TermLinks";

const cx = className.bind(styles);
const PostsGrid = dynamic(() => import('@/components/PostsGrid/PostsGrid').then((mod) => mod.default));
// const Newsletter = dynamic(() => import('@/components/Newsletter/Newsletter').then((mod) => mod.default));
const ShareBar = dynamic(() => import('@/components/ShareBar/ShareBar').then((mod) => mod.default));
const RelatedStories = dynamic(() => import('@/components/RelatedStories/RelatedStories').then((mod) => mod.default));
const PostSidebar = dynamic(() => import('@/components/PostSidebar/PostSidebar').then((mod) => mod.default));
const AdSlotPostSideBar = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.PostSideBar));
const AdSlotFooterSticky = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.FooterSticky));

// Track postInView in child component to avoid Post re-rendering
const PostWrapper = function ({post, children, ...props}) {
  const slotLevelAdTargetContext = useContext(SlotLevelAdTargetContext);
  const setShareData = useShareDateStore((state) => state.setData);

  const {momPostLayerData} = post;
  const {section} = slotLevelAdTargetContext || {};

  // Track if post is in view
  const ref = useRef(null);
  const isPostInView = useInView(ref, {
    margin: `-50% 0px -50% 0px`,
    once: false,
  });

  useEffect(() => {
    if (isPostInView) {
      // Pick title
      const title = post?.seo?.title || post.title;

      // Push url to history only if it's not already there
      if (window.location.pathname !== post.uri) {
        history.pushState(null, title, post.uri);

        // GTM event
        if (momPostLayerData) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(JSON.parse(momPostLayerData));
        }

        // IPSOS
        sendIpsosEvent(section);

        // TODO: tracking events can go here
      }

      // Update share date
      setShareData({
        url: post.uri,
        title,
        imageUrl: post.featuredImage?.node?.sourceUrl
      });
    }
  }, [isPostInView]);

  return <div ref={ref} {...props}>{children}</div>;
};

export default function Post(
  {
    post,
    latestNews,
    promotedPosts,
    siteSettings,
    className,
    positionIndex = 0,
  }
) {
  const {
    content,
    categories,
    momRelatedStories,
    momAdTargetingPost,
    momShowAd
  } = post;
  // Leave here in case we go back to keyLatestPosts
  // latestNews = latestNews?.edges?.map((edge) => edge.node)?.filter((node) => node.databaseId !== post.databaseId);

  // Exclude the post itself from the lists (failsafe)
  promotedPosts = promotedPosts?.filter((p) => p.databaseId !== post.databaseId);
  latestNews = latestNews?.filter((p) => p.databaseId !== post.databaseId);

  // Ad targeting
  const slotLevelTargeting = JSON.parse(momAdTargetingPost);

  // Custom fields
  const {
    isFeaturedPost,
    normalPostBlock: {showingBig: normalFullWidth,} = {},
    featuredPostBlock: {fullOrStandard: featuredFullWidth,} = {}
  } = post?.postSettings || {};

  // Primary category
  const primaryCategory = getPrimaryCategory(categories?.edges);

  const isFullWidth =
    isFeaturedPost ?
      ('full' === featuredFullWidth?.toLowerCase()) :
      ('big' === normalFullWidth?.toLowerCase());

  // Determine if featured image should be preloaded
  const preloadFeaturedImage = positionIndex === 0;

  return (
    <SlotLevelAdTargetContext.Provider value={slotLevelTargeting}>
      <PostWrapper
        post={post}
        className={cx(
          'component',
          'mom-intrinsic',
          {'size--large': isFullWidth || isFeaturedPost},
          {'variant--featured': isFeaturedPost},
          className
        )}
      >
        <Suspense fallback={<Loading/>}>
          {siteSettings && (
            <InjectBanner
              hasContainer={true}
              settings={siteSettings}
              positionDesktop={2}
              positionMobile={1}
              style={{marginTop: '1rem', marginBottom: (isFeaturedPost || isFullWidth) ? '1rem' : 0}}
            />
          )}

          {(isFeaturedPost || isFullWidth) && (
            <Container>
              <PostHeader post={post} preloadImage={preloadFeaturedImage}/>
              <PostInfo className={`mb-6`} {...{post}}/>
            </Container>
          )}

          {isFeaturedPost ? (
            <>
              <ContentWrapper
                content={content}
                isFullWidth={isFullWidth}
                showAd={momShowAd}
                postPositionIndex={positionIndex}
                className={cx(['!max-w-none', 'mom-featured'])}
              />

              <Container className={cx('container')}>
                <PostFooter post={post}/>
              </Container>
            </>
          ) : (
            <Container className={cx('container')}>
              <div className={cx('contentWrapper')}>
                <ContentWrapper
                  content={content}
                  isFullWidth={isFullWidth}
                  className={cx(['!max-w-none'])}
                  showAd={momShowAd}
                  postPositionIndex={positionIndex}
                  headerContent={!isFullWidth ? <PostHeader post={post} preloadImage={preloadFeaturedImage}/> : null}
                  beforeContent={
                    /* This is the best way so far to add below to above content, while we still can support featured post */
                    <PostInfo className={`mb-6`} {...{post}}/>
                  }
                  railContent={
                    <ShareBar
                      url={post.uri}
                      className={classNames(`sticky top-header-mini ml-auto mr-[18px] pt-4`)}
                      facebook x email copy
                    />
                  }
                />

                <PostFooter post={post}/>
              </div>

              <PostSidebar className={classNames(
                'hidden xl:block',
                'xl:flex-none xl:w-[var(--mom--sidebar)]',
                {'xl:pt-[56px]': !isFullWidth}
              )}>
                {momShowAd && (
                  <div className={`mb-5`}>
                    <AdSlotPostSideBar postPositionIndex={positionIndex}/>
                  </div>
                )}

                <Heading className={`pt-5 mom-border border-t-2 mom-subheading`} level={'h3'}>Trending</Heading>
                <PostsGrid posts={latestNews} columnsPerRow={1} variant={'small'} gridClassName={`grid-cols-1`}/>

                <Newsletter className={`text-center`} variant={'sidebar'} description={''} showAwards={true}/>

                <PostsGrid posts={promotedPosts} columnsPerRow={1} isFeatured={true} gridClassName={`grid-cols-1`}/>

                {siteSettings && (
                  <InjectBanner
                    settings={siteSettings}
                    positionDesktop={1}
                    positionMobile={0}
                    className={`mb-4`}
                    preloadImage={false}
                  />
                )}

                {momShowAd && (
                  <div className={`sticky top-[calc(var(--mom--offset-top-mini)_+_15px)]`}>
                    <AdSlotPostSideBar postPositionIndex={positionIndex} adIndex={1}/>
                  </div>
                )}
              </PostSidebar>
            </Container>
          )}

          {momShowAd && (
            <AdSlotFooterSticky postPositionIndex={positionIndex} objectType={'article'}/>
          )}

          <Container className={`mom-section py-6 xl:hidden`} edgePadding={false}>
            <Heading className={`mom-subheading mx-4 xl:mx-0`} level={'h3'}>Trending</Heading>
            <PostsGrid
              posts={latestNews}
              className={`pl-4 xl:pl-container xl:-mx-container`}
              columnsPerRow={1}
              variant={'small'}
              gridClassName={`grid-cols-1`}
              scrollable={true}
            />
          </Container>

          {/* No need to check postSettings.postDisableRelatedPosts, it has been checked from backend */}
          {(momRelatedStories && momRelatedStories?.length > 0) && (
            <Container className={`mom-section`}>
              <RelatedStories posts={momRelatedStories} seeMoreUrl={primaryCategory?.node?.uri}/>
            </Container>
          )}
        </Suspense>
      </PostWrapper>
    </SlotLevelAdTargetContext.Provider>
  );
}

export const PostMemo = memo((props) => {
  return (
    <Post {...props}/>
  );
});

Post.fragments = {
  keyPost: 'PostFragment',
  entryPost: gql`
    ${PostHeader.fragments.entry}
    ${PostFooter.fragments.entry}
    ${PostCard.fragments.entry}
    ${TermLinks.fragments.entryCategory}
    fragment PostFragment on Post {
      ...${PostHeader.fragments.key}
      ...${PostFooter.fragments.key}
      databaseId
      content
      uri
      postSettings {
        isFeaturedPost
        featuredPostBlock {
          fullOrStandard
        }
        normalPostBlock {
          showingBig
        }
      }
      momShowAd
      momAdTargetingPost
      momPostLayerData
      categories {
        ...${TermLinks.fragments.keyCategory}
      }
      momRelatedStories {
        ...${PostCard.fragments.key}
      }
    }
  `,

  /*keyLatestPosts: 'LatestPostsFragment',
  entryLatestPosts: gql`
    ${PostCard.fragments.entry}
    fragment LatestPostsFragment on RootQueryToPostConnection {
      edges {
        node {
          ...${PostCard.fragments.key}
        }
      }
    }
  `*/
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  /*latestNews: PropTypes.object,*/ // Leave here in case we go back to keyLatestPosts
  latestNews: PropTypes.arrayOf(PropTypes.object),
  promotedPosts: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  positionIndex: PropTypes.number,
};
