import classNames from 'classnames';
import PropTypes from "prop-types";
import {gql} from "@apollo/client";
import Link from "next/link";
import {getReactiveContent} from "@/lib/utils/helpers";
import {URI_ABOUT_US} from "@/lib/utils/constants";
// import dynamic from "next/dynamic";
import PostAuthorImage from "../PostAuthorImage/PostAuthorImage";
import Heading from "../Heading/Heading";

// const Collapse = dynamic(() => import('../Collapse/Collapse').then((mod) => mod.default));

export default function PostAuthor({className, publicAuthor, showAboutLink = true}) {
  const {
    fullName,
    firstName,
    avatarUrlSmall,
    uri,
    desc,
    title,
  } = publicAuthor;

  return (
    <div className={classNames('[&+&]:mt-10', className)}>
      <Link
        href={uri}
        className={classNames('grid grid-cols-[63px_1fr] gap-3 no-underline mb-2.5 max-w-fit')}
        prefetch={false}
      >
        <PostAuthorImage size={63} alt={fullName} avatarUrl={avatarUrlSmall}/>

        <div className={classNames('name')}>
          <p className={classNames('mt-1.5 mb-0')}>{title}</p>
          <Heading className={classNames('text-h4')} level={'h2'}>{fullName}</Heading>
        </div>
      </Link>

      <div className={classNames('mb-4 lg:text-[23px] font-serif italic')}>
        {/* Only when desc has more than 20 words, wrap with Collapse */}
        {desc && (getReactiveContent({htmlString: desc}))}
      </div>

      <div className={classNames('grid grid-cols-2 gap-x-5')}>
        <Link href={uri} prefetch={false}>More about {firstName}</Link>

        {showAboutLink && (
          <div className={`text-right`}><Link href={URI_ABOUT_US} prefetch={false}>About Man of Many</Link></div>
        )}
      </div>
    </div>
  );
}

PostAuthor.propTypes = {
  className: PropTypes.string,
  publicAuthor: PropTypes.object.isRequired,
  showAboutLink: PropTypes.bool,
};

PostAuthor.fragments = {
  key: 'PostAuthorFragment',
  entry: gql`
    fragment PostAuthorFragment on MomPublicUser {
      firstName
      fullName
      avatarUrlSmall
      uri
      desc
      title
    }
  `
};
