import {create} from 'zustand';
import {getConcatenatedUrlParts} from "../utils/helpers";

/**
 * Share data
 */
export const useShareDateStore = create((set) => ({
  url: '',
  title: '',
  text: '', // For native share
  imageUrl: '',

  setData: ({url, title, imageUrl}) => {
    // Add host if not present in url
    if (url && !url.startsWith('http')) {
      url = getConcatenatedUrlParts(process.env.NEXT_PUBLIC_FRONTEND_URL, url);
    }

    set({url, title, imageUrl, text: title});
  },
}));
