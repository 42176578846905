import category from './category';
import tag from './tag';
import frontPage from './front-page';
import archivePost from './archive-post';
import pageContact from './page-contact';
import pageNews from './page-news';
import author from './author';
import page from './page';
import single from './single';

export default {
  'front-page': frontPage,
  'page-contact': pageContact,
  'page-news': pageNews,
  'archive-post': archivePost,
  home: archivePost,
  author,
  category,
  tag,
  page,
  single,
};
