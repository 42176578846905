import ArchivePosts from '@/components/ArchivePosts/ArchivePosts';

export default function Component(props) {
  return <ArchivePosts {...props} />;
}

Component.query = ArchivePosts.entry.category;

Component.variables = (seed, ctx, extra) => {
  return ArchivePosts.variables(seed, ctx, extra);
};
