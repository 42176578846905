import Link from "next/link";
import ReactDomServer from "react-dom/server";
// import sanitizeHtml from "sanitize-html";
import {autop} from "@wordpress/autop";
import parse, {attributesToProps, domToReact} from "html-react-parser";
// import tempImage from "../assets/images/temp.jpg";
// import {useRef} from "react";
import dynamic from "next/dynamic";
import {getWpHostname} from '@faustwp/core';
// import {useInView} from "framer-motion";
import {isbot} from "isbot";
import Script from "next/script";

const Newsletter = dynamic(() => import('@/components/Newsletter/Newsletter').then((mod) => mod.default));
const ShareBar = dynamic(() => import("@/components/ShareBar/ShareBar").then((mod) => mod.default));
// const JWPlayerWrapper = dynamic(() => import("@/components/JWPlayerWrapper/JWPlayerWrapper").then((mod) => mod.default), {ssr: false});
// const Collapse = dynamic(() => import('@/components/Collapse/Collapse').then((mod) => mod.default));
const InstagramEmbed = dynamic(() => import('react-social-media-embed').then(mod => mod.InstagramEmbed), {ssr: false});
const TikTokEmbed = dynamic(() => import('react-social-media-embed').then(mod => mod.TikTokEmbed), {ssr: false});
const TwitterEmbed = dynamic(() => import('react-social-media-embed').then(mod => mod.XEmbed), {ssr: false});
const AdSlotPostInline = dynamic(() => import('@/components/GPT/GPT').then((mod) => mod.AdSlot.PostInline));

/**
 * Split array into chunks.
 * @param array
 * @param size
 * @returns {*[]}
 */
export function chunkArray(array, size) {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

/**
 * Get featured image from image fragment with fallback
 * @param imageEdge
 * @returns {*}
 */

/*export function getFeaturedImageWithFallback(imageEdge) {
  let image = imageEdge?.node;

  if (!image) {
    image = {};
    image.sourceUrl = tempImage;
    image.altText = 'Man of Many';
    image.mediaDetails = {width: 1200, height: 900};
  }

  return image;
}*/

/**
 * Get primary category from Yoast fragment
 * @param categoryEdges
 * @returns {unknown}
 */
export function getPrimaryCategory(categoryEdges) {
  if (!categoryEdges) {
    return null;
  }

  // Sample categories object can be found in components/Post/Post.js
  let primaryCategory = categoryEdges?.find(({isPrimary}) => isPrimary);

  if (!primaryCategory) {
    primaryCategory = categoryEdges[0];
  }

  return primaryCategory;
}

/**
 * Get page numbers array for pagination.
 * @param currentPage
 * @param totalPages
 * @returns {*[]}
 */
export function getPageNumbers(currentPage, totalPages) {
  const pages = [];
  let leftSide = currentPage - 2;
  let rightSide = currentPage + 2;
  let leftEllipsesAdded = false;
  let rightEllipsesAdded = false;

  if (totalPages > 1) {
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || i === currentPage || (i >= leftSide && i <= rightSide)) {
        pages.push(i);
      } else if (i < leftSide && !leftEllipsesAdded) {
        pages.push('...');
        leftEllipsesAdded = true;
      } else if (i > rightSide && !rightEllipsesAdded) {
        pages.push('...');
        rightEllipsesAdded = true;
      }
    }
  }

  return pages;
}

/**
 * Parse content to react components
 * @returns {JSX.Element|ReturnType<typeof domToReact>}
 */
export function getReactiveContent(
  {
    htmlString,
    applyAutoP = false,
    applyImgWrapper = false,
    applyAd = false,
    applyAdPostPositionIndex = 0,
  }
) {
  let sanitizedContent = applyAutoP ? autop(htmlString) : htmlString;

  /* Reconsider it, as it's too heavy for front end */
  /*let sanitizedContent = sanitizeHtml((applyAutoP ? autop(htmlString) : htmlString), {
    /!*allowVulnerableTags: true,
    allowedTags: false,*!/
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'iframe']),
    allowedAttributes: false
  });*/

  // console.log(sanitizedContent);
  // console.log('re-render reactive Content: ', sanitizedContent.slice(0, 100));

  // Remove host from all links
  sanitizedContent = getRemovedHostContent(sanitizedContent);

  // Wrap all images with a div instead of a p tag, to prepare for the Image component
  sanitizedContent = sanitizedContent.replaceAll(/<p>\s*(<img[^>]+>)\s*<\/p>/gi, '<div class="wp-block-image">$1</div>');

  // Replace double spaces, to avoid react hydration issues
  sanitizedContent = sanitizedContent.replaceAll(/\s{2,}/g, ' ');

  const options = {
    trim: false,
    replace({name, attribs, children}) {
      if (!attribs) {
        return;
      }

      switch (name) {
        case 'script':
          if (
            attribs?.src?.includes('widget.gleamjs.io')
          ) {
            return (
              <Script src={attribs.src} async strategy={'lazyOnload'}/>
            );
          }
          return <></>;

        case 'aside':
          if (
            attribs?.class?.includes('comp-further-reading')
          ) {
            return (
              <aside
                {...attributesToProps(attribs)}
                className={`comp-further-reading mom-border border-2 py-8 px-6 mb-4 lg:mb-7 lg:mx-5`}
              >
                {domToReact(children)}
              </aside>
            );
          }
          break;

        case 'div':
          // Inline ads
          if (
            attribs?.class?.includes('mom-ads__wrapper') &&
            attribs?.['data-ad-index']
          ) {
            const adIndex = attribs['data-ad-index'];

            if (applyAd) {
              return (
                <AdSlotPostInline
                  postPositionIndex={applyAdPostPositionIndex}
                  adIndex={adIndex}
                />
              );
            }

            return <></>;
          } else

            // bimg-load images
          if (
            attribs?.class?.includes('bimg-load') &&
            attribs?.['data-image-url']
          ) {
            const imgUrl = attribs['data-image-url'];
            const filenameWithoutExtension = imgUrl?.split('/').pop().split('.').shift();

            return (
              // Do not use Image component here, so we can load the images directly
              <span className={'mom-img__wrapper bimg-load'}>
                <img
                  src={imgUrl}
                  alt={filenameWithoutExtension}
                />
              </span>
            );
          } else

            // Replace jwplayer
          if (attribs?.class?.includes('block-mom-jwplayer')) {
            /*
            // Look for exact .comp-jwplayer in children
            const jwplayer = children.find(child => {
              return child.attribs?.class?.includes('comp-jwplayer') && child.attribs?.['data-playlist-id']?.length;
            });

            if ('undefined' !== typeof jwplayer) {
              const {
                'data-playlist-id': playlistId,
                'data-player-id': playerId,
                'data-media-id': mediaId,
              } = jwplayer.attribs;

              // Only block with subtitle will have hr and heading
              const isAutoAppended = attribs?.class?.includes('block-mom-jwplayer--with-subtitle');

              // Detect if the video wrapper is in view
              const size = ('undefined' !== typeof window) ? window?.innerWidth : null;
              const ref = useRef(null);
              const isInView = useInView(ref, {once: true, margin: "100px 0px 100px 0px"});
              const [visible, setVisible] = useState(false);

              useEffect(() => {
                if (
                  isInView &&
                  (
                    (size?.width && size.width >= 768) ||
                    !isAutoAppended
                  )
                ) {
                  setVisible(true);
                }
              }, [isInView]);

              return (
                <div ref={ref}>
                  {(visible && !isBotTraffic()) && (
                    <JWPlayerWrapper {...{playlistId, playerId, mediaId, isAutoAppended}}/>
                  )}
                </div>
              );
            }
            */

            return <></>;
          } else

            // Replace newsletter
          if (
            attribs?.class?.includes('block-mom-newsletter') ||
            attribs?.class?.includes('comp-newsletter-box')
          ) {
            return (
              <div className={`mom-border border-2 py-8 px-6 mb-4 lg:mb-7 lg:mx-5`}>
                <Newsletter
                  className={`text-center lg:max-w-[480px] lg:[&_h3]:text-[34px] mx-auto`}
                  showAwards={true}
                  description={''}
                />
              </div>
            );
          } else

            // Replace Yoast table of content
          if (attribs?.class?.includes('wp-block-yoast-seo-table-of-contents')) {
            return <></>;
            /* This has been extracted as a separate block, so we can position it anywhere */
            /*return (
              <Collapse variant={'toc'}>
                <div {...attributesToProps(attribs)}>
                  {domToReact(children, options)}
                </div>
              </Collapse>
            );*/
          } else

            // TODO: Isolate this from the final build
            // Replace shoes converter, eg: https://manofmany.com/fashion/australian-mens-shoe-size-conversion-guide
          if (attribs?.id) {
            switch (attribs.id) {
              case 'converter-container':
                const ShoesSizeConverter = () => {
                  function convertShoeSize() {
                    const size = parseFloat(document.getElementById('shoeSize').value);
                    const type = document.getElementById('sizeType').value;

                    const sizeConversions = {
                      AUS_UK: {
                        AUS_UK: size,
                        US: size + 0.5,
                        EU: size + 33,
                        JP: size + 25,
                        CM: (size + 18) * 2.54,
                        IN: (size + 18)
                      },
                      US: {
                        AUS_UK: size - 0.5,
                        US: size,
                        EU: size + 33.5,
                        JP: size + 25.5,
                        CM: (size + 18.5) * 2.54,
                        IN: (size + 18.5)
                      },
                      EU: {
                        AUS_UK: size - 33,
                        US: size - 33.5,
                        EU: size,
                        JP: size - 8,
                        CM: (size - 15) * 2.54,
                        IN: (size - 15)
                      },
                      JP: {
                        AUS_UK: size - 25,
                        US: size - 25.5,
                        EU: size + 8,
                        JP: size,
                        CM: (size - 7) * 2.54,
                        IN: (size - 7)
                      },
                      CM: {
                        AUS_UK: (size / 2.54) - 18,
                        US: (size / 2.54) - 18.5,
                        EU: (size / 2.54) + 15,
                        JP: (size / 2.54) + 7,
                        CM: size,
                        IN: size / 2.54
                      },
                      IN: {AUS_UK: size - 18, US: size - 18.5, EU: size + 15, JP: size + 7, CM: size * 2.54, IN: size}
                    };

                    if (isNaN(size)) {
                      document.getElementById('aus_ukSize').innerText = "Aus/NZ/UK: N/A";
                      document.getElementById('usSize').innerText = "US: N/A";
                      document.getElementById('euSize').innerText = "Europe/France/Italy: N/A";
                      document.getElementById('jpSize').innerText = "Japan: N/A";
                      document.getElementById('cmSize').innerText = "CM: N/A";
                      document.getElementById('inSize').innerText = "Inches: N/A";
                      return;
                    }

                    const results = sizeConversions[type];
                    document.getElementById('aus_ukSize').innerText = "Aus/NZ/UK: " + results.AUS_UK.toFixed(1);
                    document.getElementById('usSize').innerText = "US: " + results.US.toFixed(1);
                    document.getElementById('euSize').innerText = "Europe/France/Italy: " + results.EU.toFixed(1);
                    document.getElementById('jpSize').innerText = "Japan: " + results.JP.toFixed(1);
                    document.getElementById('cmSize').innerText = "CM: " + results.CM.toFixed(1);
                    document.getElementById('inSize').innerText = "Inches: " + results.IN.toFixed(1);
                  }

                  return (
                    <div id="converter-container">
                      <h2>Men's Shoe Size Converter</h2>
                      <label htmlFor="shoeSize">Enter Size:</label>
                      <input type="number" id="shoeSize" placeholder="Enter size" onInput={convertShoeSize}/>
                      <select id="sizeType" onChange={convertShoeSize}>
                        <option value="AUS_UK">Aus/NZ/UK</option>
                        <option value="US">US</option>
                        <option value="EU">Europe/France/Italy</option>
                        <option value="JP">Japan</option>
                        <option value="CM">CM</option>
                        <option value="IN">Inches</option>
                      </select>

                      <div id="sizeResults">
                        <p id="aus_ukSize">Aus/NZ/UK: </p>
                        <p id="usSize">US: </p>
                        <p id="euSize">Europe/France/Italy: </p>
                        <p id="jpSize">Japan: </p>
                        <p id="cmSize">CM: </p>
                        <p id="inSize">Inches: </p>
                      </div>
                    </div>
                  );
                };

                return <ShoesSizeConverter/>;

              case 'macro-calculator':
                const MacroCalculator = () => {
                  function calculateMacros() {
                    const age = parseInt(document.getElementById('age').value);
                    const weight = parseInt(document.getElementById('weight').value);
                    const height = parseInt(document.getElementById('height').value);
                    const activity = parseFloat(document.getElementById('activity').value);
                    const goal = document.getElementById('goal').value;

                    // Basal Metabolic Rate (Mifflin-St Jeor Equation)
                    const bmr = 10 * weight + 6.25 * height - 5 * age + 5;

                    // Total Daily Energy Expenditure
                    let tdee = bmr * activity;

                    // Adjust calories based on goal
                    if (goal === 'lose') {
                      tdee *= 0.85; // reduce by 15%
                    } else if (goal === 'gain') {
                      tdee *= 1.15; // increase by 15%
                    }

                    // Macros distribution: 30% protein, 40% carbs, 30% fats
                    const proteins = Math.round((tdee * 0.3) / 4); // 1 gram of protein = 4 calories
                    const carbs = Math.round((tdee * 0.4) / 4); // 1 gram of carbs = 4 calories
                    const fats = Math.round((tdee * 0.3) / 9); // 1 gram of fat = 9 calories

                    document.getElementById('calories').innerText = 'Calories: ' + Math.round(tdee);
                    document.getElementById('proteins').innerText = 'Protein (g): ' + proteins;
                    document.getElementById('carbs').innerText = 'Carbohydrates (g): ' + carbs;
                    document.getElementById('fats').innerText = 'Fats (g): ' + fats;
                  }

                  return (
                    <div id="macro-calculator">
                      <h2>Macronutrient Calculator</h2>
                      <form onSubmit={e => {
                        e.preventDefault();
                        calculateMacros();
                      }}>
                        <div>
                          <label htmlFor="age">Age:</label>
                          <input type="number" id="age" required/>
                        </div>
                        <div>
                          <label htmlFor="weight">Weight (kg):</label>
                          <input type="number" id="weight" required/>
                        </div>
                        <div>
                          <label htmlFor="height">Height (cm):</label>
                          <input type="number" id="height" required/>
                        </div>
                        <div>
                          <label htmlFor="activity">Activity Level:</label>
                          <select id="activity">
                            <option value="1.2">Sedentary (little or no exercise)</option>
                            <option value="1.375">Lightly active (light exercise/sports 1-3 days/week)</option>
                            <option value="1.55">Moderately active (moderate exercise/sports 3-5 days/week)</option>
                            <option value="1.725">Very active (hard exercise/sports 6-7 days a week)</option>
                            <option value="1.9">Extra active (very hard exercise & physical job)</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="goal">Goal:</label>
                          <select id="goal">
                            <option value="lose">Lose Weight</option>
                            <option value="maintain">Maintain Weight</option>
                            <option value="gain">Gain Muscle</option>
                          </select>
                        </div>
                        <button type="submit">Calculate Macros</button>
                      </form>
                      <div id="results">
                        <p id="calories">Calories: N/A</p>
                        <p id="proteins">Protein (g): N/A</p>
                        <p id="carbs">Carbohydrates (g): N/A</p>
                        <p id="fats">Fats (g): N/A</p>
                      </div>
                    </div>
                  );
                };

                return <MacroCalculator/>;
            }
          }
          break;

        case 'blockquote':
          // Embed content
          // Twitter
          if (
            attribs?.class?.includes('twitter-tweet')
          ) {
            // Parse DOMNode children to string
            const childrenString = ReactDomServer.renderToString(domToReact(children));

            // Find the tweet url in children string
            const tweetUrlMatch = childrenString.match(/https:\/\/twitter\.com\/.*\/status\/(\d+)/);

            if (!tweetUrlMatch) {
              return;
            }

            // Lazy load
            // const ref = useRef(null);
            // const isInView = useInView(ref, {once: true, margin: "100px 0px 100px 0px"});
            const isInView = true;

            return (
              <>
                {isInView && (
                  <TwitterEmbed
                    style={{
                      maxWidth: 550
                    }}
                    url={tweetUrlMatch[0]}
                    width="100%"
                  />
                )}
              </>
            );
          } else

            // Instagram
          if (
            attribs?.class?.includes('instagram') &&
            attribs['data-instgrm-permalink']
          ) {
            // Lazy load
            // const ref = useRef(null);
            // const isInView = useInView(ref, {once: true, margin: "100px 0px 100px 0px"});
            const isInView = true;

            return (
              <>
                {isInView && (
                  <InstagramEmbed
                    style={{
                      maxWidth: 550
                    }}
                    url={attribs['data-instgrm-permalink']}
                    width="100%"
                  />
                )}
              </>
            );
          } else

            // Tiktok
          if (
            attribs?.class?.includes('tiktok') &&
            attribs['cite']
          ) {
            // Lazy load
            // const ref = useRef(null);
            // const isInView = useInView(ref, {once: true, margin: "200px 0px 200px 0px"});
            const isInView = true;

            return (
              <>
                {isInView && (
                  <TikTokEmbed
                    style={{
                      maxWidth: 550
                    }}
                    url={attribs['cite']}
                    width="100%"
                  />
                )}
              </>
            );
          }
          break;

        /* Disable for now, as some posts don't work */
        /*case 'iframe':
          // Lazy load iframes
          if (
            attribs.src
          ) {
            const ref = useRef(null);
            const isInView = useInView(ref, {once: true, margin: "100px 0px 100px 0px"});

            return (
              <div ref={ref}>
                {isInView && (
                  <iframe {...attributesToProps(attribs)}/>
                )}
              </div>
            );
          }
          break;*/

        case 'img':
          // Wrap image if needed
          if (
            attribs.src &&
            !attribs?.class?.includes('mom-skip--reactive')
          ) {
            // Replace images
            if (applyImgWrapper) {
              // TODO: need to reconsider this re app bandwidth
              /*return (
                <Image
                  src={attribs.src}
                  alt={attribs.alt}
                  width={900}
                  height={600}
                  {...attributesToProps(attribs)}
                />
              );*/


              return (
                <span className={'group mx-auto block relative'}>
                  <img alt={attribs?.alt}{...attributesToProps(attribs)}/>
                  <ShareBar
                    className={'mom-share-bar'}
                    url={attribs.src}
                    variant={'no-border'}
                    facebook x pinterest copy
                  />
                </span>
              );
            }
          }
          break;

        case 'a':
          // Replace legacy buttons
          if (
            attribs?.class?.includes('ch_custom') &&
            attribs?.href
          ) {
            // add mom-btn mom-btn--fill to class
            attribs.class = attribs.class + ' mom-btn mom-btn--fill';

            return (
              <Link {...attributesToProps(attribs)} prefetch={false}>{domToReact(children)}</Link>
            );
          }

          // Replace <a> tag with Next.js Link for internal links only, except hash links and images.
          if (
            attribs?.href &&
            !attribs.href.match(/\.(png|jpg|jpeg|gif|svg)$/i)
          ) {
            // As all link domains have been replaced above
            if (attribs.href.startsWith('/')) {
              return (
                <Link {...attributesToProps(attribs)} prefetch={false}>
                  {domToReact(children)}
                </Link>
              );
            }

            if (attribs.href.startsWith('#')) {
              // remove target attr
              delete attribs.target;

              return (
                <a {...attributesToProps(attribs)}>
                  {domToReact(children)}
                </a>
              );
            }
          }
          break;
      }
    },
  };

  return parse(sanitizedContent, options);
}

/**
 * Remove all domain variations of site urls, except images, so NextJS Link can work properly.
 * @param htmlString
 * @returns {*}
 */
export function getRemovedHostContent(htmlString) {
  if (!htmlString) {
    return '';
  }

  let finalHtmlString = htmlString;

  [
    'http://localhost:3000',
    'https://manofmany.com',
    'https://local.manofmany.com',
    'https://dev.manofmany.com',
    `https://${getWpHostname()}`,
  ].forEach(url => {
    finalHtmlString = finalHtmlString.replaceAll(
      new RegExp(`(${url}[^"'#]*)`, 'gi'),
      (match, p1) => {
        if (p1.match(/\.(png|jpg|jpeg|gif|svg)/i)) {
          return p1;
        }

        return p1.replaceAll(new RegExp(`(${url})`, 'gi'), '');
      }
    );
  });

  return finalHtmlString;
}

/**
 * Get stripped HTML tags.
 * @param html
 * @returns {*}
 */
export function getStripHtmlTagsStr(html) {
  return html.replace(/<[^>]*>?/gm, '');
}

/**
 * Get concatenated URL parts.
 * @param args
 * @returns {string}
 */
export function getConcatenatedUrlParts(...args) {
  return args.join('/').replace(/([^:]\/)\/+/g, "$1").replace(/\/$/, '');
}

/**
 * Get adjacent uri, i.e., next or previous page.
 * @param uri
 * @param currentPage
 * @param dir
 * @returns {string}
 */
export function getAdjacentUri(uri, currentPage, dir = 0) {
  switch (dir) {
    case -1:
      if (currentPage - 1 === 1) {
        return getConcatenatedUrlParts(uri);
      }

      return getConcatenatedUrlParts(uri, `/page/${currentPage - 1}`);
    case 1:
      return getConcatenatedUrlParts(uri, `/page/${currentPage + 1}`);
  }

  return getConcatenatedUrlParts(uri, `${currentPage > 1 ? `/page/${currentPage}` : ''}`);
}

/**
 * Get adjacent page number, i.e., next or previous page.
 * @param content
 * @returns {number}
 */
export function getWordsCount(content) {
  return content.split(' ').length;
}

/**
 * Decode HTML entities.
 * @param text
 * @returns {*}
 */
export function decode(text) {
  // Add more chars when found. No need to use a library
  const entities = {
    '#038': '&',
    'amp': '&',
    'apos': '\'',
    'lt': '<',
    '#060': '<',
    'gt': '>',
    '#062': '>',
    'quot': '"',
    '#034': '"',
    'nbsp': '\xa0'
  };

  const entityPattern = /&([a-z]+|#\d+);/ig;

  return text.replace(entityPattern, function (match, entity) {
    entity = entity.toLowerCase();
    if (entities.hasOwnProperty(entity)) {
      return entities[entity];
    }
    // return original string if there is no matching entity (no replace)
    return match;
  });
}

/**
 * Check if the user agent is a bot.
 * @returns {boolean}
 */
export function isBotTraffic() {
  return ('undefined' !== typeof navigator) && isbot(navigator?.userAgent);
}

/**
 * Send Ipsos event.
 * @param section
 */
export function sendIpsosEvent(section) {
  if (
    ('undefined' !== typeof section) &&
    ('dm' in window)
  ) {
    dm.AjaxEvent('pageview', null, section);
  }
}

/**
 * Get formatted title.
 * @param title
 * @param italicLast
 * @param delimiters
 * @returns {*|JSX.Element}
 */
export function getStyledTitle({title, italicLast = true, delimiters = [':', ';', '  ',]}) {
  if (!title) {
    return title;
  }

  let titleParts = [title];
  let usedDelimiter = '';

  for (const delimiter of delimiters) {
    if (title.includes(delimiter)) {
      titleParts = title.split(delimiter);
      usedDelimiter = delimiter;
      break;
    }
  }

  if (titleParts.length === 1) {
    return title;
  }

  const mainTitle = titleParts[0];
  const subTitle = titleParts.slice(1).join(usedDelimiter);

  return (
    italicLast ? (
      <>
        {mainTitle}{usedDelimiter}<i>{subTitle}</i>
      </>
    ) : (
      <>
        <i>{mainTitle}</i>{usedDelimiter}{subTitle}
      </>
    )
  );
}

/**
 * Get object context for defining type of current page
 * @param seedNode
 */
/*export function getCurrentObjectType(seedNode) {
  if (seedNode?.isFrontPage) {
    return 'home';
  }

  if (seedNode?.__typename === 'Post') {
    return 'article';
  }

  if (seedNode?.__typename === 'Category' || seedNode?.__typename === 'Tag') {
    return 'archive';
  }

  if (seedNode?.__typename === 'Page') {
    return 'page';
  }
}*/
