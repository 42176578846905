import {gql} from '@apollo/client';
import Image from 'next/image';
import classNames from "classnames";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";

const ShareBar = dynamic(() => import('@/components/ShareBar/ShareBar').then((mod) => mod.default));

export default function FeaturedImage(
  {
    image,
    width,
    height,
    className,
    priority,
    fill,
    shareEnabled = false,
    ...props
  }
) {
  const src = image?.sourceUrl;
  const {altText} = image || '';

  width = width ? width : image?.mediaDetails?.width;
  height = height ? height : image?.mediaDetails?.height;
  fill = fill || false;

  return src && width && height ? (
    <figure className={classNames('group', className)}>
      <Image
        src={src}
        alt={altText}
        fill={fill}
        priority={priority}
        width={!fill ? width : undefined}
        height={!fill ? height : undefined}
        {...props}
        className={`w-full h-full object-cover`}
      />

      {shareEnabled && (
        <ShareBar
          url={src}
          className={classNames('mom-share-bar')}
          variant={'no-border'}
          facebook x pinterest copy
        />
      )}
    </figure>
  ) : null;
}

FeaturedImage.fragments = {
  key: `FeaturedImageFragment`,
  entry: gql`
    fragment FeaturedImageFragment on NodeWithFeaturedImageToMediaItemConnectionEdge {
      node {
        id
        sourceUrl
        altText
        mediaDetails {
          width
          height
        }
      }
    }
  `
};

FeaturedImage.propTypes = {
  image: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  priority: PropTypes.bool,
  fill: PropTypes.bool,
  shareEnabled: PropTypes.bool,
};
