import classNames from 'classnames';
import Button from "@/components/Button/Button";
import React, {useCallback, useState, useTransition} from "react";
import IconShare from "@/lib/assets/images/share.svg";
import {useShareDateStore} from "@/lib/stores/post";
import {AnimatePresence} from "framer-motion";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import {BUTTON_VARIANTS, SIZES} from "@/lib/utils/constants";

const ShareDropdown = dynamic(() => import('@/components/ShareDropdown/ShareDropdown').then((mod) => mod.default), {ssr: false});

export default function PostShare(
  {
    className,
    variant = 'default',
    size = 'default'
  }
) {
  const [, startTransition] = useTransition();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const shareData = useShareDateStore();

  const handleTriggerClick = () => {
    if ('undefined' !== typeof navigator.share) {
      navigator.share(shareData).then(() => {
      }).catch(err => {
        console.log(err);
      });
    } else {
      startTransition(() => {
        setDropdownVisible(!dropdownVisible);
      });
    }
  };

  const handleCloseDropdown = useCallback(() => {
    startTransition(() => {
      setDropdownVisible(false);
    });
  }, []);

  return (
    <>
      <Button
        className={classNames(
          {'mom-active-border': dropdownVisible},
          'max-md:mom-btn--sm max-md:font-normal',
          className
        )}
        size={size}
        variant={variant}
        onClick={handleTriggerClick}
        aria-label={'Share'}
      ><IconShare/>Share</Button>

      <AnimatePresence>
        {dropdownVisible && (
          <ShareDropdown closeDropdown={handleCloseDropdown}/>
        )}
      </AnimatePresence>
    </>
  );
}

PostShare.propTypes = {
  variant: PropTypes.oneOf(BUTTON_VARIANTS),
  size: PropTypes.oneOf(SIZES),
  className: PropTypes.string,
};
