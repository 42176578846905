import {create} from 'zustand';
import {setNewsletterPopupCookie} from '../utils/cookies';

/**
 * Current object store, i.e., a home/article/page/archive
 */
export const useCurrentObjectStore = create((set, get) => ({
  type: false,

  setType: (type) => set({type}),
}));

/**
 * Footer newsletter popup store
 */
export const useStickyNewsletterStore = create((set, get) => ({
  visible: false,

  toggle: () => set((state) => {
    if (!get().visible) {
      setNewsletterPopupCookie();
    }
    return {visible: !state.visible};
  }),

  show: () => set(() => {
    setNewsletterPopupCookie();
    return {visible: true};
  }),

  hide: () => set({visible: false}),
}));

/**
 * Sticky footer ad popup store
 */
export const useStickyFooterAdStore = create((set, get) => ({
  visible: false,

  setVisibility: (visibility) => set({visible: visibility}),
}));

/**
 * Primary menu store
 */
export const usePrimaryMenuStore = create((set) => ({
  visible: false,

  toggle: () => {
    useSearchOverlayStore.getState().hide();
    set((state) => ({visible: !state.visible}));
  },

  show: () => {
    useSearchOverlayStore.getState().hide();
    set({visible: true});
  },

  hide: () => set({visible: false}),
}));

/**
 * Search screen store
 */
export const useSearchOverlayStore = create((set) => ({
  visible: false,

  toggle: () => {
    usePrimaryMenuStore.getState().hide();
    set((state) => ({visible: !state.visible}));
  },

  show: () => {
    usePrimaryMenuStore.getState().hide();
    set({visible: true});
  },

  hide: () => set({visible: false}),
}));

/**
 * Sticky header store
 */
export const useStickyHeaderStore = create((set) => ({
  sticky: false,

  setSticky: (sticky) => set({sticky}),
}));
