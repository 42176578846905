import {gql} from '@apollo/client';
import InjectBanner from "@/components/InjectBanner/InjectBanner";
import HeroBanner from "@/components/HeroBanner/HeroBanner";

export const GlobalFragment = gql`
  ${InjectBanner.fragments.entry}
  ${HeroBanner.fragments.entry}
  fragment GlobalFragment on HeadlessSiteSettings {
    ...${InjectBanner.fragments.key}
    ...${HeroBanner.fragments.key}
    siteSettings {
      facebookUrl
      twitterUrl
      instagramUrl
      youtubeUrl
      tiktokUrl
      googleNews
      linkedinUrl
      redditUrl
      pinterestUrl
    }
  }
`;

export const FooterFragment = gql`
  fragment FooterFragment on HeadlessSiteSettings {
    siteSettings {
      footerAffiliateDisclaimer
    }
  }
`;
