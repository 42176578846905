import classNames from 'classnames';
import PropTypes from "prop-types";
import React from "react";
import Link from "next/link";

import ImgFacebook from '@/lib/assets/images/soc-fb-w.svg';
import ImgTwitter from '@/lib/assets/images/soc-tw-w.svg';
import ImgInstagram from '@/lib/assets/images/soc-ig-w.svg';
import ImgYoutube from '@/lib/assets/images/soc-yt-w.svg';
import ImgTiktok from '@/lib/assets/images/tiktok-w.svg';
import ImgGoogleNews from '@/lib/assets/images/google-news-white.svg';
import ImgLinkedin from '@/lib/assets/images/linkedin-w.svg';
import ImgReddit from '@/lib/assets/images/reddit-w.svg';
import ImgPinterest from '@/lib/assets/images/share_pinterest-white.svg';

const list = {
  facebookUrl: ImgFacebook,
  twitterUrl: ImgTwitter,
  instagramUrl: ImgInstagram,
  youtubeUrl: ImgYoutube,
  tiktokUrl: ImgTiktok,
  googleNews: ImgGoogleNews,
  linkedinUrl: ImgLinkedin,
  redditUrl: ImgReddit,
  pinterestUrl: ImgPinterest,
};

export default function SocialProfiles({className, ...props}) {
  return (
    <ul className={classNames(
      className,
      `p-0 m-0 flex flex-wrap w-full justify-center gap-x-10 gap-y-5`,
    )}>
      {Object.keys(list).map((key) => {
        if (!props?.[key]) {
          return null;
        }

        const url = props[key]?.includes('http') ? props[key] : ((value) => {
          switch (key) {
            case 'twitterUrl':
              return `https://twitter.com/${value}`;
            case 'mailTo':
              return `mailto:${value}`;
            default:
              return value;
          }
        })(props[key]);

        if (!url) {
          return null;
        }

        return (
          <li key={`${key}`} className={`list-none`}>
            <Link
              aria-label={`${key}`}
              href={url ?? ''}
              target="_blank"
              className={classNames([
                'group',
                'inline-block',
                'p-0',
              ])}
              rel="noopener noreferrer"
              prefetch={false}
            >
              {React.createElement(list?.[key], {
                className: classNames(
                  'size-[20px] lg:size-[38px] object-contain',
                  '!text-[currentColor] dark:!text-[currentColor]',
                  'group-hover:!text-navy dark:group-hover:!text-silver',
                  'group-hover:!fill-navy dark:group-hover:!fill-silver',
                  'group-hover:[&_path]:!fill-navy dark:group-hover:[&_path]:!fill-silver',
                )
              })}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

SocialProfiles.propTypes = {
  className: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  tiktokUrl: PropTypes.string,
  googleNews: PropTypes.string,
  linkedinUrl: PropTypes.string,
  redditUrl: PropTypes.string,
  pinterestUrl: PropTypes.string,
};
