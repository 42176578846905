import dynamic from "next/dynamic";
import {object, string} from 'yup';
import PropTypes from 'prop-types';
import {useState} from "react";
import classNames from 'classnames';

import Button from "@/components/Button/Button";
import Heading from "@/components/Heading/Heading";
import {useStickyNewsletterStore} from "@/lib/stores/layout";
import {API_FORM_NEWSLETTER} from "@/lib/utils/constants";
import IconClose from '@/lib/assets/images/icon-close-w.svg';
import IconLogo from "@/lib/assets/images/logo-footer-w.svg";
import IconTrophy from '@/lib/assets/images/trophy.svg';
import {getStyledTitle} from "@/lib/utils/helpers";

const Formik = dynamic(() => import('formik').then(mod => mod.Formik));
const Field = dynamic(() => import('formik').then(mod => mod.Field));
const Form = dynamic(() => import('formik').then(mod => mod.Form));

const validationSchema = object().shape({
  email: string().email('Invalid email address').required('Email is required'),
});

export default function Newsletter(
  {
    className,
    variant = 'default',
    heading = 'Join Our  Exclusive Community!', /* intended double spaces */
    showAwards = false,
    description = 'Keep up with the latest trends, best stories, and crucial updates from Man of Many direct to your inbox.',
  }
) {
  const isVariantDefault = variant === 'default';
  const isVariantFullWidth = variant === 'fullWidth';
  const isVariantSidebar = variant === 'sidebar';
  const isVariantInline = variant === 'inline';

  const hideNewsletter = useStickyNewsletterStore(state => state.hide);
  const [resMessage, setResMessage] = useState('');

  const handleSubmit = async (values) => {
    const response = await fetch(API_FORM_NEWSLETTER, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(values),
    });

    if (response.ok && response.status === 200) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'newsletter_signup'
      });
    }

    const data = await response.json();
    setResMessage(data.message);
  };

  const Inner = ({children}) => (
    <>
      {isVariantFullWidth ? (
        <>
          <Button
            variant={'icon'}
            onClick={hideNewsletter}
            className={classNames('cursor-pointer absolute top-[18px] right-[15px] p-1')}
            aria-label="Close newsletter"
          >
            <IconClose className={`!size-[15px]`}/>
          </Button>

          <div className={classNames('px-5 mx-auto max-w-[1240px] md:flex md:items-center md:justify-center')}>
            <IconLogo className={classNames('mr-[50px] hidden w-[170px] md:inline-block')}></IconLogo>

            <div>{children}</div>
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );

  return (
    <div className={classNames(
      {'': isVariantDefault},
      {'px-4 pt-8 pb-10 mom-border border-2 mb-8': isVariantSidebar},
      {'px-0 pt-5 pb-7 lg:pt-7 border-t-2 mom-border mom-backdrop': isVariantFullWidth},
      {'text-center max-md:p-6 max-md:bg-navy-300 dark:max-md:bg-silver-300': isVariantInline},
      className
    )}>
      <Inner>
        {heading.length > 0 && (
          <Heading
            level={'h3'}
            className={classNames(
              {'text-h5 lg:text-h2 max-md:uppercase max-md:tracking-widest max-md:font-sans max-md:text-navy dark:max-md:text-silver': isVariantInline},
            )}
          >
            {getStyledTitle({title: heading, italicLast: false})}
          </Heading>
        )}

        {description.length > 0 && (
          <p>{description}</p>
        )}

        <div className={classNames(
          {'mx-auto max-w-[710px] lg:flex lg:items-center lg:justify-center gap-10': isVariantInline},
        )}>
          {showAwards && (
            <div className={classNames(
              {'mb-5 lg:mb-7': !isVariantInline},
              {'max-md:mb-5 lg:text-left': isVariantInline},
            )}>
              <p className={`mb-1`}>
                <IconTrophy className={`inline-block mr-1 text-navy dark:text-silver`}/> <b>WINNER</b>
                <i className={`font-serif text-sm`}>– Best Media Platform, 2023</i>
              </p>
              <p className={`mb-1`}>
                <IconTrophy className={`inline-block mr-1 text-navy dark:text-silver`}/> <b>WINNER</b>
                <i className={`font-serif text-sm`}>– Website of the Year, 2023</i>
              </p>
            </div>
          )}

          <Formik
            initialValues={{email: '',}}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({errors, touched, isSubmitting}) => {
              const isInvalidEmail = errors.email && touched.email;

              return (
                <Form className={classNames(
                  'flex relative flex-col gap-4',
                  {'md:flex-row': !isVariantSidebar},
                  {'max-w-[600px]': isVariantFullWidth},
                  {'w-full lg:max-w-[400px]': isVariantInline},
                  {'': isVariantDefault},
                )}>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    autoComplete="email"
                    className={classNames(
                      'h-11 text-base bg-[transparent] border rounded-full px-4 w-full',
                      'text-black dark:text-white border-black dark:border-white'
                    )}
                  />
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant={`fill`}
                    className={classNames(
                      'font-bold !h-11'
                    )}
                  >
                    Subscribe
                  </Button>
                  {(isInvalidEmail && errors.email) || (resMessage !== '' && resMessage) ? (
                    <div
                      className={classNames('absolute text-red text-[13px] bottom-[-20px] left-0 whitespace-nowrap overflow-hidden text-ellipsis')}>
                      {isInvalidEmail ? errors.email : resMessage}
                    </div>
                  ) : null}
                </Form>
              );
            }}
          </Formik>
        </div>
      </Inner>
    </div>
  );
};

Newsletter.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'fullWidth', 'sidebar', 'inline']),
  heading: PropTypes.string,
  showAwards: PropTypes.bool || PropTypes.number,
  description: PropTypes.string,
};
