import classNames from 'classnames';
import PropTypes from "prop-types";
import {forwardRef} from 'react';
import {BUTTON_VARIANTS, SIZES} from "@/lib/utils/constants";

const btnVariants = {
  default: 'mom-btn--default',
  icon: 'mom-btn--icon',
  fill: 'mom-btn--fill',
};

const btnSizes = {
  default: '',
  small: 'mom-btn--sm',
};

const Button = forwardRef(
  (
    {
      children,
      variant = 'default',
      size = 'default',
      className,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(
          'mom-btn',
          className,
          btnVariants[variant],
          btnSizes[size],
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(BUTTON_VARIANTS),
  size: PropTypes.oneOf(SIZES),
  className: PropTypes.string,
};

export default Button;
