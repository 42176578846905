import classNames from 'classnames';

import Heading from '../Heading/Heading';
import {getReactiveContent} from "@/lib/utils/helpers";
import PropTypes from "prop-types";

export default function EntryHeader({title, description, className}) {
  return (
    <div className={classNames(
      'text-center py-10',
      className
    )}>
      {!!title && <Heading level={'h1'} className={classNames('lg:text-xxl')}>{title}</Heading>}
      {!!description && (
        <div
          className={classNames(
            'lg:text-[23px] mx-auto lg:max-w-[790px]',
            'font-serif italic'
          )}
        >
          {getReactiveContent({
            htmlString: description,
            applyAutoP: true
          })}
        </div>
      )}
    </div>
  );
}

EntryHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};
