import {getStyledTitle} from "@/lib/utils/helpers";
import Heading from "@/components/Heading/Heading";
import Link from "next/link";
import * as constants from '@/lib/utils/constants';

export default function Statistics() {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 xl:gap-x-5">
      <div className="text-center xl:text-left mb-12 xl:mb-0">
        <Heading level={`h2`} className={`italic font-normal text-h4 md:text-h1 mb-6 xl:mb-14`}>
          How We test Products
        </Heading>
        <p
          className={`font-serif font-bold text-h5 md:text-h4 mb-4 xl:mb-8`}>{getStyledTitle({title: 'We test properly: objective and subjective testing'})}
        </p>
        <p
          className={`font-serif font-bold text-h5 md:text-h4 mb-4 xl:mb-8`}>{getStyledTitle({title: 'Experienced experts  for our reviews'})}
        </p>
        <p
          className={`font-serif font-bold text-h5 md:text-h4 mb-4 xl:mb-8`}>{getStyledTitle({title: 'Always 100 per cent  unbiased, independent opinions'})}
        </p>

        <Link href={constants.URI_HOW_WE_TEST} className={`mom-btn mt-4 xl:mt-8`}>Learn more how we test</Link>
      </div>

      <div className={`text-center grid grid-cols-2 grid-rows-2 gap-x-6 gap-y-12`}>
        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            36+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>Editors and Experts</p>
          <p>Testing products and services</p>
        </div>

        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            800K+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>Editors and Experts</p>
          <p>Testing products and services</p>
        </div>

        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            150K+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>Editors and Experts</p>
          <p>Testing products and services</p>
        </div>

        <div className={`col-span-1 row-span-1`}>
          <Heading
            level={`h3`}
            className={`text-h1 lg:text-[76px]/[0.9] mb-3 lg:mb-0 text-navy dark:text-silver`}
          >
            150K+
          </Heading>
          <p className={`font-serif font-bold text-h5 lg:text-[26px] mb-2 lg:mb-0`}>Editors and Experts</p>
          <p>Testing products and services</p>
        </div>
      </div>
    </div>
  );
};
